<template>
  <div id="app">
    <div class="main">
      <div class="container">
        <div>
          <h4 class="text-primary">CÁCH TÍNH BIÊN LỢI NHUẬN SHOPEE</h4>
          <a class="text-danger" href="https://atosa.asia/share/FILE GIÁ BÁN SẢN PHẨM SHOPEE SYT.xlsx">
            Tải file tính toán gốc</a>
        </div>
        <div class="row" style="margin-top: 25px">
          <div class="col-sm" id="col-1" style="padding-right: 50px">
            <p style="font-weight: 600; font-size: 17px" class="text-warning">- GÍA SẢN PHẨM</p>

            <form>
              <div class="mb-3">
                <label for="exampleFormControlInput1" class="form-label col-form-label-sm">Giá nhập (Ví dụ:
                  55000):</label>
                <input type="number" class="form-control form-control-sm" id="exampleFormControlInput1"
                       placeholder="Giá nhập...." name="import_price" v-model="gia_nhap">
              </div>
              <div class="mb-3">
                <label for="exampleFormControlInput2" class="form-label col-form-label-sm">Giá bán (Ví dụ:
                  85000):</label>
                <input type="number" class="form-control form-control-sm" id="exampleFormControlInput2"
                       placeholder="Giá bán...." name="sale_price" v-model="gia_ban">
              </div>
              <div class="mb-3">
                <label for="exampleFormControlInput12" class="form-label col-form-label-sm">Phí ship:</label>
                <input type="number" class="form-control form-control-sm" id="exampleFormControlInput12"
                       placeholder="Phí ship...." name="import_price" v-model="phi_nhan_hang">
              </div>
              <!--              <div class="mb-3">
                              <label for="exampleFormControlInput13" class="form-label col-form-label-sm">Kho bãi (chi phí
                                khác):</label>
                              <input type="number" class="form-control form-control-sm" id="exampleFormControlInput13"
                                     placeholder="Kho bãi...." name="sale_price" v-model="kho_bai">
                            </div>-->
            </form>
          </div>
          <div class="col-sm" style="padding-right: 50px">
            <p style="font-weight: 600; font-size: 17px" class="text-warning">- VẬT TƯ ĐÓNG GÓI</p>

            <div class="mb-3">
              <label for="exampleFormControlInput5" class="form-label col-form-label-sm">Giá hộp:</label>
              <input type="number" class="form-control form-control-sm" id="exampleFormControlInput5"
                     placeholder="Giá hộp...." name="import_price" v-model="gia_hop">
            </div>
            <div class="mb-3">
              <label for="exampleFormControlInput6" class="form-label col-form-label-sm">Giá vật tư (băng keo,
                thư...):</label>
              <input type="number" class="form-control form-control-sm" id="exampleFormControlInput6"
                     placeholder="Giá vật tư...." name="sale_price" v-model="gia_vat_tu">
            </div>
            <div class="mb-3">
              <label for="exampleFormControlInput7" class="form-label col-form-label-sm">Giá quà tặng (ticker, chun
                tóc...):</label>
              <input type="number" class="form-control form-control-sm" id="exampleFormControlInput7"
                     placeholder="Giá quà tặng...." name="import_price" v-model="gia_qua_tang">
            </div>
            <div class="mb-3">
              <label for="exampleFormControlInput8" class="form-label col-form-label-sm">Lương nhân công:</label>
              <input type="number" class="form-control form-control-sm" id="exampleFormControlInput8"
                     placeholder="Lương nhân công...." name="sale_price" v-model="luong_nhan_cong">
            </div>
          </div>
        </div>

        <div class="row" style="margin-top: 5px">
          <div class="col-sm" style="padding-right: 50px">
            <p style="font-weight: 600; font-size: 17px" class="text-warning">- PHÍ SÀN</p>

            <form>
              <div class="mb-3">
                <label for="exampleFormControlInput9" class="form-label col-form-label-sm">Phí giao dịch (3% tổng giá
                  bán và ship):</label>
                <input type="number" class="form-control form-control-sm" id="exampleFormControlInput9"
                       placeholder="Phí giao dịch...." name="import_price" v-model="phi_giao_dich">
              </div>
              <div class="mb-3">
                <label for="exampleFormControlInput10" class="form-label col-form-label-sm">Phí extra (7% tối đa
                  20,000):</label>
                <input type="number" class="form-control form-control-sm" id="exampleFormControlInput10"
                       placeholder="Phí extra...." name="sale_price" v-model="phi_extra">
              </div>
              <div class="mb-3">
                <label for="exampleFormControlInput11" class="form-label col-form-label-sm">Phí hoàn xu (4% tối đa
                  20,000):</label>
                <input type="number" class="form-control form-control-sm" id="exampleFormControlInput11"
                       placeholder="Phí hoàn xu...." name="sale_price" v-model="phi_hoan_xu">
              </div>
            </form>
          </div>
          <div class="col-sm" style="padding-right: 50px">
            <!--            <p style="font-weight: 600; font-size: 17px" class="text-warning">- CHI PHÍ KHÁC</p>-->
            <p style="font-weight: 600; font-size: 17px" class="text-danger">- KẾT QUẢ</p>
            <table cellspacing="0" cellpadding="0">
              <tr>
                <td style="width: 220px"><strong>Doanh thu:</strong>
                  <div style="height: 5px"></div>
                </td>
                <td>{{ m__doanh_thu() | currency }} đ</td>
              </tr>
              <tr>
                <td style="width: 220px"><strong>Lợi nhuận:</strong>
                  <div style="height: 5px"></div>
                </td>
                <td>{{ m__loi_nhuan() | currency }} đ</td>
              </tr>
              <tr>
                <td style="width: 220px"><strong>Phần trăm lợi nhuận:</strong></td>
                <td>{{ m__phan_tram_loi_nhuan() }} %</td>
              </tr>
            </table>

            <br>
            <br>
            <button type="button" class="btn btn-danger btn-sm">Tải file tính toán gốc</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {},
  data() {
    return {
      gia_nhap: null,
      gia_ban: null,
      gia_hop: null,
      gia_vat_tu: null,
      gia_qua_tang: null,
      luong_nhan_cong: null,
      phi_giao_dich: null,
      phi_extra: null,
      phi_hoan_xu: null,
      phi_nhan_hang: null,
      kho_bai: null,
    }
  },

  watch: {
    'gia_ban': function (val) {
      if (val && Number(val) > 0) {
        this.phi_giao_dich = (Number(this.phi_nhan_hang) + Number(val)) * 0.03
        this.phi_extra = Number(val) * 0.07 > 20000 ? 20000 : Number(val) * 0.07
        this.phi_hoan_xu = Number(val) * 0.04 > 20000 ? 20000 : Number(val) * 0.04
      }
    },

    'phi_nhan_hang': function (val) {
      if (val && Number(val) > 0) {
        this.phi_giao_dich = (Number(this.gia_ban) + Number(val)) * 0.03
      }
    }
  },

  methods: {
    m__doanh_thu() {
      return this.gia_ban - this.phi_giao_dich - this.phi_extra - this.phi_hoan_xu
    },

    m__loi_nhuan() {
      return this.gia_ban - this.phi_giao_dich - this.phi_extra - this.phi_hoan_xu - this.gia_hop - this.gia_vat_tu - this.gia_qua_tang - this.luong_nhan_cong - this.gia_nhap
    },

    m__phan_tram_loi_nhuan() {
      if (this.m__doanh_thu() !== 0) {
        return (this.m__loi_nhuan() / this.m__doanh_thu() * 100).toFixed(2)
      }
      return 0
    }
  }
}
</script>

<style>
.main {
  width: 100%;
  padding: 30px 0;
}

label {
  font-weight: 600;
}

.el-button .text-success {
  color: #3c763d !important;
}

.text-warning {
  color: #8a6d3b !important;
}

.text-danger {
  color: #a94442 !important;
}
</style>
