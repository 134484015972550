import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false

new Vue({
    render: h => h(App),
}).$mount('#app')

function format_currency(value, defaultValue = true) {
    return value && !Number.isNaN(value) ? parseInt(value.toString()).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,') : (defaultValue ? 0 : '')
}

Vue.filter('format_currency', format_currency)
Vue.filter('currency', format_currency)
